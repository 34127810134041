<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo del Formulario -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        Evaluación de Satisfacción de Calidad
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <!-- Contenido -->
     <v-col cols="12">
        <v-form ref="form" lazy-validation>
          <v-card
            flat
            width="auto"
          >
            <v-card-text>
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <!-- Muestro el listado de Areas -->
                <v-col 
                  cols="12" 
                  md="4" 
                  sm="4" 
                  class="py-1"
                  v-for="(item, index) in areas"
                  :key="index"
                >
                  <v-card
                    elevation="2"
                    outlined
                  >
                    <v-card-title style="word-break: normal;" class="text-center d-flex">
                      <v-icon class="ma-2">{{ item.icono }}</v-icon>{{ (($vuetify.breakpoint.sm || $vuetify.breakpoint.md || $vuetify.breakpoint.lg) && item.area_nombre.toString().length > 25) ? `${item.area_nombre.toString().substring(0, 22)}...` : item.area_nombre }}
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete
                            v-model="item.calificacion"
                            label="Calificación"
                            item-text="calif_nombre"
                            item-value="calif_id"
                            :items="calificaciones"
                            outlined
                            dense
                            :rules="[rules.calif]"
                            validate-on-blur
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <v-textarea
                            v-model="item.observacion"
                            outlined
                            label="Observación"
                            dense
                            :rules="[rules.obs]"
                            validate-on-blur
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <BtnConfirmar
                @action="guardarEvaluacion()"
                color="success"
                type="submit"
                :texto="`¿Esta seguro de finalizar la Evaluación?`"
                nombre="Guardar"
                v-if="permite_grabar"
              />
            </v-card-actions>
          </v-card>
        </v-form>
     </v-col>
  </div>
</template>

<script>
import router from '../../router'
import { cadenaVacia } from '../../util/utils'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue';
export default {
  data() {
    return {
      areas: [],
      calificaciones: [],
      evaluacion: {},
      rules: {
        calif: value => !cadenaVacia(value) || 'Debe seleccionar una Calificación.',
        obs: value => !cadenaVacia(value) || 'Debe ingresar una Observación.'
      },
      permite_grabar: true
    }
  },
  components: {
    BtnConfirmar,
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm() {
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('evaluaciones/init_eval_calidad')
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      if (initPeticion.evaluaciones.length > 1 || initPeticion.evaluaciones.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron áreas para evaluar o bien no exiten camapañas abiertas para evaluación. Usted sera redireccionado a la página de inicio.',
          color: 'info',
        })
        this.permite_grabar = false
        setTimeout(() => [router.push({ path: "/" })], 3000)
        return
      }
      this.areas = initPeticion.areas
      this.calificaciones = initPeticion.calificaciones
      this.evaluacion = initPeticion.evaluaciones[0]
    },
    async guardarEvaluacion(){
      if (!this.$refs.form.validate()) return
      this.$store.state.loading = true
      let savePeticion = await this.$store.dispatch('evaluaciones/guardar_eval_calidad', {
        campania: this.evaluacion.id,
        evaluacion: this.areas
      })
      this.$store.state.loading = false
      if (savePeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Evaluación Finalizada con éxito.',
        color: 'success',
      })
      setTimeout(() => [router.push({ path: "/" })], 3000)
    }
  },
  watch: {
    areas: function(){
      if (this.areas.length > 0){
        for (let id in this.areas){
          Object.defineProperty(this.areas[id], 'calificacion',{
            value: null, 
            writable: true, 
            enumerable: true, 
            configurable: true
          })
          Object.defineProperty(this.areas[id], 'observacion',{
            value: '', 
            writable: true, 
            enumerable: true, 
            configurable: true
          })
        }
      }
    }
  },
}
</script>

<style>

</style>